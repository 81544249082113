<template>
  <b-card class="message-card">
    <div class="d-lg-flex justify-content-between align-items-center">
      <div class="message-card__body">
        <h2>
          <slot name="title"></slot>
        </h2>

        <p class="mb-0">
          <slot name="body"></slot>
        </p>
      </div>

      <div class="d-lg-flex justify-content-end message-card__action">
        <slot name="action"></slot>
      </div>
    </div>

    <div class="message-card__badge">
      <slot name="badge"></slot>
    </div>
  </b-card>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
::v-deep .card {
  background-color: red;
}

.message-card {
  max-width: 1008px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  padding: 80px;
  h2 {
    font-weight: bold;
    font-size: 24px;
  }

  &__inner {
    position: relative;
  }

  &__body {
    // TODO: update font-size after redesign release
    p {
      font-size: 1.25rem;
    }
  }

  &__badge {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
  }
}

@media (max-width: 990px) {
  .message-card {
    padding: 40px;

    &__action {
      margin-top: 1rem;
    }
  }
}
</style>
