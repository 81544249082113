<template>
  <component :is="component" />
</template>

<script>
// libs
import { mapState } from "vuex";

// components
import MessageV1 from "@/components/messages/MessageV1.vue";
import MessageV2 from "@/components/messages/MessageV2.vue";

export default {
  components: {
    MessageV1,
    MessageV2,
  },

  computed: {
    ...mapState("configuration", ["config"]),

    ...mapState("user", {
      user: "currentUser",
    }),

    component() {
      const statesForNewModal = this.config.admin_chat;
      const isShowNewModal = statesForNewModal.includes(this.user.state);

      return isShowNewModal ? "MessageV2" : "MessageV1";
    },
  },
};
</script>
