var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "my-5 my-md-6" },
    [
      _c(
        "b-row",
        { attrs: { "align-h": "center" } },
        [
          _c("b-col", { staticClass: "col-auto" }, [
            _c("h3", { staticClass: "heading display-2 mb-0 message-header" }, [
              _vm._v("Messages"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        {
          staticClass: "mt-6",
          staticStyle: { position: "relative" },
          attrs: { "align-h": "center", "align-v": "center" },
        },
        [
          _c(
            "b-col",
            [
              _c("MessageCard", {
                staticClass: "message-card--support",
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [_vm._v(" Chat with Patient Services ")]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _vm._v(
                          " Have a question about your membership, packages or billing? "
                        ),
                        _c("br", { staticClass: "d-none d-md-block" }),
                        _vm._v(" Patient services is here to help! "),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "badge",
                    fn: function () {
                      return [
                        _vm.unreadAdminMessagesCount > 0
                          ? _c(
                              "b-badge",
                              {
                                staticClass: "badge badge--number",
                                attrs: { pill: "", variant: "primary" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.unreadAdminMessagesCount) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "action",
                    fn: function () {
                      return [
                        _c(
                          "b-button",
                          {
                            staticClass: "px-5",
                            attrs: { pill: "", variant: "info", size: "lg" },
                            on: {
                              click: function ($event) {
                                return _vm.routeTo("message-admin")
                              },
                            },
                          },
                          [_vm._v(" Chat now ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("MessageCard", {
                staticClass: "message-card--doctor",
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [_vm._v(" Contact Your Physician ")]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _vm._v(
                          " Message your Winona doctor here about changes in "
                        ),
                        _c("br", { staticClass: "d-none d-md-block" }),
                        _vm._v(" medication, treatment, or health. "),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "action",
                    fn: function () {
                      return [
                        _c(
                          "b-button",
                          {
                            staticClass: "btn-action px-5",
                            attrs: {
                              pill: "",
                              variant: "outline-dark",
                              size: "lg",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.routeTo("message-doctor")
                              },
                            },
                          },
                          [_vm._v(" Contact Doctor ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "badge",
                    fn: function () {
                      return [
                        _vm.currentUser.needspatientreply
                          ? _c(
                              "b-badge",
                              {
                                staticClass: "badge badge--text",
                                attrs: { pill: "", variant: "primary" },
                              },
                              [_vm._v(" reply needed ")]
                            )
                          : _vm._e(),
                        _vm.unreadMessagesCount > 0
                          ? _c(
                              "b-badge",
                              {
                                staticClass: "badge badge--number",
                                attrs: { pill: "", variant: "primary" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.unreadMessagesCount) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }