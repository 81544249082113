<template>
  <b-container class="p-0" fluid>
    <chat
      v-if="seeMessage"
      :conversation="conversation"
      id="chatList"
      v-on:scroll.passive="onScroll"
      :userid="userid"
    />
  </b-container>
</template>

<script>
import { ChatService } from "@/services/chat.service.js";
import { mapGetters } from "vuex";

import chat from "@/components/partials/Chat";
export default {
  components: {
    chat,
  },
  data() {
    return {
      seeMessage: false,
      userid: "",
      isLoading: true,
      conversation: {},
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  methods: {
    onScroll(e) {
      this.$emit("scroll", e);
    },
    async getConversation() {
      try {
        this.isLoading = true;
        const { data: conversation } = await ChatService.listConversation();
        this.conversation = conversation[0];
        const conversationData = await ChatService.getConversation(
          conversation[0]?.id
        );
        this.conversation.banned_date = conversationData?.data?.banned_date;
        this.conversation.isbanned = conversationData?.data?.isbanned;
        this.isLoading = false;
      } catch (error) {
        console.log(error.message);
      }
    },
  },
  async mounted() {
    if (window.Intercom) {
      this.$nextTick(() => {
        window.Intercom("boot", {
          app_id: process.env.VUE_APP_INTERCOM_APP_ID,
          hide_default_launcher: true,
          custom_launcher_selector: ".intercom-launcher",
        });
      });
    }
    await this.getConversation();
    await this.$store.dispatch("user/getUser");
    this.userid = localStorage.getItem("userid");
    this.seeMessage = true;
  },
};
</script>
