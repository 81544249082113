var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-card", { staticClass: "message-card" }, [
    _c(
      "div",
      { staticClass: "d-lg-flex justify-content-between align-items-center" },
      [
        _c("div", { staticClass: "message-card__body" }, [
          _c("h2", [_vm._t("title")], 2),
          _c("p", { staticClass: "mb-0" }, [_vm._t("body")], 2),
        ]),
        _c(
          "div",
          { staticClass: "d-lg-flex justify-content-end message-card__action" },
          [_vm._t("action")],
          2
        ),
      ]
    ),
    _c("div", { staticClass: "message-card__badge" }, [_vm._t("badge")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }