<template>
  <b-container class="my-5 my-md-6">
    <b-row align-h="center">
      <b-col class="col-auto">
        <h3 class="heading display-2 mb-0 message-header">Messages</h3>
      </b-col>
    </b-row>

    <b-row
      style="position: relative"
      align-h="center"
      align-v="center"
      class="mt-6"
    >
      <b-col>
        <MessageCard class="message-card--support">
          <template #title> Chat with Patient Services </template>

          <template #body>
            Have a question about your membership, packages or billing?
            <br class="d-none d-md-block" />
            Patient services is here to help!
          </template>

          <template #badge>
            <b-badge
              class="badge badge--number"
              v-if="unreadAdminMessagesCount > 0"
              pill
              variant="primary"
            >
              {{ unreadAdminMessagesCount }}
            </b-badge>
          </template>

          <template #action>
            <b-button
              pill
              variant="info"
              size="lg"
              class="px-5"
              @click="routeTo('message-admin')"
            >
              Chat now
            </b-button>
          </template>
        </MessageCard>

        <MessageCard class="message-card--doctor">
          <template #title> Contact Your Physician </template>

          <template #body>
            Message your Winona doctor here about changes in
            <br class="d-none d-md-block" />
            medication, treatment, or health.
          </template>

          <template #action>
            <b-button
              pill
              variant="outline-dark"
              size="lg"
              class="btn-action px-5"
              @click="routeTo('message-doctor')"
            >
              Contact Doctor
            </b-button>
          </template>

          <template #badge>
            <b-badge
              class="badge badge--text"
              pill
              variant="primary"
              v-if="currentUser.needspatientreply"
            >
              reply needed
            </b-badge>

            <b-badge
              class="badge badge--number"
              v-if="unreadMessagesCount > 0"
              pill
              variant="primary"
            >
              {{ unreadMessagesCount }}
            </b-badge>
          </template>
        </MessageCard>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// libs
import { mapState } from "vuex";

// components
import MessageCard from "@/components/messages/MessageCard.vue";

export default {
  components: {
    MessageCard,
  },

  computed: {
    ...mapState("message", ["unreadMessagesCount", "unreadAdminMessagesCount"]),
    ...mapState("user", ["currentUser"]),
  },

  methods: {
    routeTo(routeName) {
      this.$router.push({ name: routeName });
    },
  },

  async created() {
    this.$store.dispatch("user/getUser");
    this.$store.dispatch("message/getAdminUnreadMessageCount");
    this.$store.dispatch("message/getUnreadMessageCount");
  },
};
</script>

<style lang="scss" scoped>
.message-header {
  line-height: 1;
  font-size: 3.5rem;
}

.message-card {
  .badge {
    font-size: 14px;
    border: 1px solid transparent;
    @include flex-center();

    &--number {
      @include wh(28px);
    }

    &--text {
      height: 30px;
    }
  }

  &--support {
    background-color: #f5f2ff;

    ::v-deep .card-body {
      padding: 0 !important;
    }

    ::v-deep button {
      color: white;
    }
  }

  &--doctor {
    background-color: #eff0f1;

    ::v-deep .card-body {
      padding: 0 !important;
    }

    ::v-deep .badge {
      &--number {
        background-color: #f58c42;
        text-transform: uppercase;
      }

      &--text {
        background-color: transparent;
        border-color: #f58c42;
        color: #f58c42;
        font-size: 14px;
        text-transform: uppercase;
        padding-inline: 15px;
      }
    }

    ::v-deep .btn-action.btn {
      background-color: #ffffff;
      // TODO: updata bg color to "transparent" after redesign release
      color: #020203 !important;
    }
  }
}

@media (max-width: 990px) {
  .message-header {
    font-size: 2.5rem;
  }

  .message-card {
    &--doctor {
      padding-top: 60px;
    }
  }
}
</style>
